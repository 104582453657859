import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import Search from '../Search';

import styles from './style.module.scss';

const MODES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
};

const BusinessNavSearch = ({
    mode = MODES.DESKTOP,
    className,
    isVisible = true,
    theme = 'default',
    bsmDetails = {},
    rollouts = {},
}) => {
    const displayClasses = classNames(
        styles.fiverrBusinessHeaderSearchAnimated,
        {
            [styles.show]: isVisible,
            [styles.searchDesktop]: mode === MODES.DESKTOP,
            [styles.searchMobile]: mode === MODES.MOBILE,
        },
        className
    );

    const placeholder = translate('header.business.search_pro_placeholder_mobile');

    return (
        <Search
            className={displayClasses}
            theme={theme}
            rollouts={rollouts}
            businessType="none"
            {...bsmDetails}
            placeholder={placeholder}
        />
    );
};

BusinessNavSearch.MODES = MODES;

BusinessNavSearch.propTypes = {
    theme: PropTypes.string,
    bsmDetails: PropTypes.shape({
        businessType: PropTypes.string,
        bsm: PropTypes.object,
    }),
    mode: PropTypes.oneOf(['mobile', 'desktop']),
    className: PropTypes.string,
    isVisible: PropTypes.bool,
    rollouts: PropTypes.object,
};

export default BusinessNavSearch;
