import { getContext } from '@fiverr-private/fiverr_context';
import { HEADER_BUSINESS_SOURCE } from '../../../constants';
import { useTryWithDelayAttempt, SIGN_TYPE, getMigrationUrl, getRegistrationUrl } from './useTryWithDelayAttempt';

export const useSignUpModal = ({ mpEvent, bqEvent, afterSigningSuccess } = {}) => {
    const { loading, triggerSignModal } = useTryWithDelayAttempt({
        signType: SIGN_TYPE.JOIN,
        mpEvent,
        bqEvent,
        openModalFunc: () => {
            window.openSignUpModal({ afterSigningSuccess }, { source: HEADER_BUSINESS_SOURCE });
        },
        onFail: () => {
            const { userId } = getContext();
            const redirectUrl = userId ? getMigrationUrl() : getRegistrationUrl();
            window.location.assign(redirectUrl);
        },
    });

    return {
        loading,
        triggerSignUpModal: triggerSignModal,
    };
};
