import React from 'react';
import { func } from 'prop-types';
import { Dropdown } from '@fiverr-private/orca';
import { AboutMenu } from './AboutMenu';
import { TriggerButton } from './TriggerButton';

import styles from './styles.module.scss';

export const BusinessProAboutNavigation = ({ onOpen }) => (
    <Dropdown
        containerClassName={styles.menuContainer}
        onOpen={onOpen}
        position={Dropdown.POSITIONS.RIGHT}
        menu={AboutMenu}
    >
        <TriggerButton />
    </Dropdown>
);

BusinessProAboutNavigation.propTypes = {
    onOpen: func,
};
