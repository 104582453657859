import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { Button } from '@fiverr-private/button';
import { Domingo } from '@fiverr-private/domingo';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { TOP_NAV_SOURCE } from '../../constants';

import styles from './index.module.scss';

export const BecomeFreelancerButton = ({
    className,
    source = TOP_NAV_SOURCE,
    intent = 'secondary',
    variant = 'ghost',
    ...props
}) => {
    const path = Domingo(getContext().url).removePath().removePro().path(
        'content_pages_page',
        { page_slug: 'pro-freelancers' },
        {
            query: { source },
        }
    ).url;

    return (
        <Button
            className={classnames(styles.button, className)}
            intent={intent}
            variant={variant}
            rel="nofollow"
            href={path}
            {...props}
        >
            <I18n k="header.become_freelancer" />
        </Button>
    );
};

BecomeFreelancerButton.propTypes = {
    className: string,
    intent: string,
    variant: string,
    source: string,
};
