import { useState, useCallback } from 'react';
import { delay } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { mixpanel, stats, logger } from '@fiverr-private/obs';
import { sendBigQueryEvent } from '../../../utils/bigQuery';
import { HEADER_BUSINESS_SOURCE } from '../../../constants';
import { STATS_PREFIX, TIMEOUT_DELAY, STATS, SIGN_TYPE } from './constants';

export { SIGN_TYPE };

export const getRegistrationUrl = () =>
    pathfinder('business_registration_page', null, {
        query: { source: HEADER_BUSINESS_SOURCE, session_type: 'sign_in' },
    });

export const getMigrationUrl = () =>
    pathfinder('business_user_migration_new', null, { query: { source: HEADER_BUSINESS_SOURCE } });

/**
 * @param {object} params
 * @param {'LOGIN'|'JOIN'} params.signType
 * @param {string} params.mpEvent
 * @param {object} params.bqEvent
 * @param {() => void} params.openModalFunc
 * @param {() => void} params.onFail
 */
export const useTryWithDelayAttempt = ({ signType, mpEvent, bqEvent, openModalFunc, onFail }) => {
    const [loading, setLoading] = useState(false);

    const triggerSignModal = useCallback(() => {
        if (mpEvent) {
            mixpanel.track(mpEvent);
        }
        if (bqEvent) {
            sendBigQueryEvent(bqEvent);
        }

        if (loading) {
            return;
        }

        try {
            openModalFunc();
        } catch (err) {
            stats.count(STATS_PREFIX, STATS[signType].STATS_NOT_LOADED);
            setLoading(true);

            delay(() => {
                try {
                    openModalFunc();
                    stats.count(STATS_PREFIX, STATS[signType].STATS_LOADED_AFTER_TO);
                } catch (failErr) {
                    stats.count(STATS_PREFIX, STATS[signType].STATS_NOT_LOADED_AFTER_TO);
                    logger.error(failErr, { description: 'open "sign-in / sign-up" modal not defined' });
                    onFail();
                }
                setLoading(false);
            }, TIMEOUT_DELAY);
        }
    }, [mpEvent, bqEvent, openModalFunc, onFail, signType, loading]);

    return {
        loading,
        triggerSignModal,
    };
};
