import { HEADER_BUSINESS_SOURCE } from '../../../constants';
import { useTryWithDelayAttempt, SIGN_TYPE, getRegistrationUrl } from './useTryWithDelayAttempt';

export const useSignInModal = ({ mpEvent, bqEvent, afterSigningSuccess } = {}) => {
    const { loading, triggerSignModal } = useTryWithDelayAttempt({
        signType: SIGN_TYPE.LOGIN,
        mpEvent,
        bqEvent,
        openModalFunc: () => {
            window.openSignInModal({ afterSigningSuccess }, { source: HEADER_BUSINESS_SOURCE });
        },
        onFail: () => {
            window.location.assign(getRegistrationUrl());
        },
    });

    return {
        loading,
        triggerSignInModal: triggerSignModal,
    };
};
