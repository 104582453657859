import { useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { shouldDisplayMigrationPopup, openFipFullMigrationModal } from '@fiverr-private/pro_migration';
import { mixpanel } from '@fiverr-private/obs';
import { sendBigQueryEvent } from '../../../utils/bigQuery';

/**
 * @param {object} params
 * @param {string} params.source
 * @param {string} params.trigger
 * @param {string} params.mpEvent
 * @param {object} params.bqEvent
 * @returns
 */
export const useFibMigrationModal = ({ mpEvent, bqEvent, source, trigger }) => {
    const { userId } = getContext();
    const isBrowser = typeof window !== 'undefined';
    const supportMigrationModal = useMemo(
        () => (userId && isBrowser ? shouldDisplayMigrationPopup() : false),
        [userId, isBrowser]
    );

    const triggerMigrationModal = useCallback(() => {
        if (mpEvent) {
            mixpanel.track(mpEvent);
        }
        if (bqEvent) {
            sendBigQueryEvent(bqEvent);
        }

        const businessHomePage = pathfinder('business_landing_page', {}, { query: { source } });
        const redirectToBusinessHomepage = () => window.location.assign(businessHomePage);

        openFipFullMigrationModal({
            biEnrichment: {
                pageName: source,
                trigger,
            },
            flowContext: {
                type: trigger,
            },
            source,
            onError: redirectToBusinessHomepage,
            onSkip: noop,
            onLoadError: redirectToBusinessHomepage,
            onSuccess: redirectToBusinessHomepage,
        });
    }, [source, trigger, mpEvent, bqEvent]);

    return {
        supportMigration: supportMigrationModal,
        triggerMigrationModal,
    };
};
