import { getContext } from '@fiverr-private/fiverr_context';
import { ALLOWED_COUNTRIES } from './allowedCountries.constants';

/**
 * README: Geo restriction: only west Europe, USA, Canada, GB, Australia
 * Read countries.json (translations) for details and check country code and country name
 */
export const isAllowedFibContact = () => {
    const { countryCode } = getContext();
    return ALLOWED_COUNTRIES.includes(countryCode);
};
