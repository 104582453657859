import React from 'react';
import { bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { DropdownTriggerButton } from '../DropdownTriggerButton';

export const TriggerButton = ({ isOpen }) => (
    <DropdownTriggerButton title={<I18n k="header.about" />} isOpen={isOpen} />
);

TriggerButton.propTypes = {
    isOpen: bool,
};
