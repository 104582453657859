import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { rollouts } from '../../PropTypes';
import withContext from '../../lib/withContext';
import Nav from '../../lib/Nav';
import Layout from '../../components/Layout';
import Row from '../../components/Row';
import { BusinessSidebarTrigger } from '../../components/BusinessSidebarTrigger';
import BusinessNavSearch from '../../components/BusinessNavSearch';
import { FiverrBusinessProLinkLogo } from '../../components/FiverrBusinessProLinkLogo';
import { BusinessLoginButton } from '../../components/BusinessLoginButton';
import { BusinessProJoinButton } from '../../components/BusinessProJoinButton';
import { BecomeFreelancerButton } from '../../components/BecomeFreelancerButton';
import { BusinessWhyFiverrProNavigation } from '../../components/BusinessWhyFiverrProNavigation';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import { BusinessContactSalesButton, isAllowedFibContact } from '../../components/BusinessContactSalesButton';

import styles from './BusinessProVisitorLoggedOut.module.scss';

import './BusinessProVisitorLoggedOut.styles.scss';

const BusinessProVisitorLoggedOutView = ({ primarySearchVisible = true }, { rollouts }) => {
    const { locale } = getContext();
    const shouldShowWhyPro = locale === 'en-US' || !primarySearchVisible;

    return (
        <Layout className="fiverr-for-business-pro">
            <Row className={classnames(styles.row, 'flex flex-items-center')}>
                <BusinessSidebarTrigger displayFromLG />
                <FiverrBusinessProLinkLogo />
                <BusinessNavSearch
                    className="m-l-24"
                    mode={BusinessNavSearch.MODES.DESKTOP}
                    theme="dark"
                    isVisible={primarySearchVisible}
                />
                <Nav className={styles.nav} displayFromSM>
                    {!rollouts.inDolphinsWhyFiverrProButton && (
                        <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                            <LeanLocaleSettingsLink />
                        </Nav.Item>
                    )}
                    <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                        <BecomeFreelancerButton />
                    </Nav.Item>
                    {shouldShowWhyPro && (
                        <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                            <BusinessWhyFiverrProNavigation />
                        </Nav.Item>
                    )}
                    {isAllowedFibContact() && rollouts.inDolphinsWhyFiverrProButton && (
                        <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                            <BusinessContactSalesButton />
                        </Nav.Item>
                    )}
                    {rollouts.inDolphinsWhyFiverrProButton && (
                        <Nav.Item className={classnames(styles.navItemButton, 'p-r-0  m-r-8')} displayFromLG>
                            <LeanLocaleSettingsLink hideLabel />
                        </Nav.Item>
                    )}
                    <Nav.Item className={classnames(styles.navItemButton, styles.signIn)} displayFromSM>
                        <BusinessLoginButton className={styles.actionBtn} />
                    </Nav.Item>
                    <Nav.Item className="p-r-0" displayFromSM>
                        <BusinessProJoinButton className={styles.actionBtn} withShortTitle />
                    </Nav.Item>
                </Nav>
            </Row>
            <BusinessNavSearch mode={BusinessNavSearch.MODES.MOBILE} theme="dark" isVisible={primarySearchVisible} />
        </Layout>
    );
};

BusinessProVisitorLoggedOutView.propTypes = {
    primarySearchVisible: PropTypes.bool,
};

BusinessProVisitorLoggedOutView.contextTypes = {
    rollouts,
};
/**
 * @type {React.FC}
 */
export const BusinessProVisitorLoggedOut = withContext(BusinessProVisitorLoggedOutView);
