import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import pathfinder from '@fiverr-private/pathfinder';
import { getBQData } from '../../utils/business/bi-utils';
import { useSignUpModal } from '../../hooks/business/useSignModal';
import { useFibMigrationModal } from '../../hooks/business/useFibMigrationModal';
import { HEADER_BUSINESS_SOURCE } from '../../constants';

import styles from './styles.module.scss';

export const BusinessProJoinButton = ({ className, withShortTitle = false }) => {
    const { userId, isMobile } = getContext();
    const isLogged = !!userId;

    const MP_EVENT = 'Clicked join on visitor header';
    const BQ_EVENT = getBQData({ type: 'join_fiverr_business' });

    const { triggerSignUpModal } = useSignUpModal({
        mpEvent: 'Clicked join on visitor header',
        bqEvent: getBQData({ type: 'join_fiverr_business' }),
    });

    const { supportMigration, triggerMigrationModal } = useFibMigrationModal({
        source: HEADER_BUSINESS_SOURCE,
        trigger: 'join_button',
        mpEvent: MP_EVENT,
        bqEvent: BQ_EVENT,
    });

    const simpleRedirectToPage = () =>
        window.location.assign(
            pathfinder('business_user_migration_new', {}, { query: { source: HEADER_BUSINESS_SOURCE } })
        );

    const onClick = () => {
        if (!isLogged) {
            triggerSignUpModal();
            return;
        }

        if (supportMigration) {
            triggerMigrationModal();
            return;
        }

        simpleRedirectToPage();
    };

    const ctaText = `header.${isMobile || withShortTitle ? 'join' : 'business_visitor.cta_pro'}`;

    return (
        <Button className={classnames(styles.button, className)} variant="outline" intent="primary" onClick={onClick}>
            <I18n k={ctaText} />
        </Button>
    );
};

BusinessProJoinButton.propTypes = {
    withShortTitle: PropTypes.bool,
    className: PropTypes.string,
};
