import React from 'react';
import { Menu } from '@fiverr-private/orca';
import { Domingo } from '@fiverr-private/domingo';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { TOP_BAR_SOURCE } from '../../constants';
import Link from '../Links/Link';
import HelpCenterLink from '../BusinessProfileMenu/links/HelpCenterLink';
import { isAllowedFibContact } from './isAllowedFibContact';

import './AboutMenu.styles.scss';

const T_PREFIX = 'header.business_about';
const COMMON_QUERY = {
    query: { source: TOP_BAR_SOURCE },
};

const getUrl = () => {
    const { url } = getContext();
    return Domingo(url).pro();
};

export const AboutMenu = () => {
    const showContactSales = isAllowedFibContact();

    const whyWorkWithAsUrl = getUrl().path('content_pages_page', { page_slug: 'how-it-works' }, COMMON_QUERY).url;
    const allAboutProTalentUrl = getUrl().path(
        'content_pages_page',
        { page_slug: 'about-pro-freelancers' },
        COMMON_QUERY
    ).url;
    const successStoriesUrl = getUrl().path('content_pages_page', { page_slug: 'success-stories' }, COMMON_QUERY).url;

    return (
        <Menu className="header-package business-entry about-menu pro">
            <Menu.Item>
                <Link href={whyWorkWithAsUrl}>
                    <I18n k={`${T_PREFIX}.why_work_with_as`} />
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link href={allAboutProTalentUrl}>
                    <I18n k={`${T_PREFIX}.all_about_pro_talent`} />
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link href={successStoriesUrl}>
                    <I18n k={`${T_PREFIX}.success_stories`} />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            {showContactSales && (
                <Menu.Item>
                    <Link href={`https://fiverr.com/fbs-contact-us?source=${TOP_BAR_SOURCE}`}>
                        <I18n k={`${T_PREFIX}.contact_sales`} />
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item>
                <HelpCenterLink isBusinessPro={true} />
            </Menu.Item>
        </Menu>
    );
};
