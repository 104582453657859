import React from 'react';
import { bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { getBQData } from '../../../../utils/business/bi-utils';
import Link from '../../../Links/Link';

const PRO_HELP_PAGE = 'https://help-pro.fiverr.com';
const HELP_PAGE = 'https://help.fiverr.com';

const HelpCenterLink = ({ isBusinessPro = false }) => (
    <Link
        href={isBusinessPro ? PRO_HELP_PAGE : HELP_PAGE}
        bigQueryData={getBQData({ type: 'help' })}
        target="_blank"
        rel="noopener noreferrer"
    >
        <I18n k="header.help" />
    </Link>
);

HelpCenterLink.propTypes = {
    isBusinessPro: bool,
};

export default HelpCenterLink;
