export const ALLOWED_COUNTRIES = [
    'US', // United States
    'VI', // U.S. Virgin Islands
    'UM', // U.S. Outlying Islands
    'CA', // Canada
    'GB', // United Kingdom
    'AU', // Australia
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czechia
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'EL', // Greece
    'HU', // Hungary
    'IE', // Ireland
    'IT', // Italy
    'IL', // Israel
    'LV', // Latvia
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'EU', // European Union
];
