import React from 'react';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { getBQData } from '../../utils/business/bi-utils';
import { useSignInModal } from '../../hooks/business/useSignModal';

export const BusinessLoginButton = ({ ...props }) => {
    const { triggerSignInModal } = useSignInModal({
        mpEvent: 'Clicked sign in on visitor header',
        bqEvent: getBQData({ type: 'sign_in_fiverr_business' }),
    });

    return (
        <Button intent="secondary" variant="ghost" onClick={triggerSignInModal} {...props}>
            <I18n k="header.sign_in" />
        </Button>
    );
};
