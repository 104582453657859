/**
 * Delay before we check to see if the join/login modal is available.
 * @type {Integer}
 */
export const TIMEOUT_DELAY = 2000;

/**
 * Prefix for the OBS stats call
 * @type {String}
 */
export const STATS_PREFIX = 'header_package.business';

export const SIGN_TYPE = {
    LOGIN: 'LOGIN',
    JOIN: 'JOIN',
};

export const STATS = {
    [SIGN_TYPE.JOIN]: {
        /** @type {string} - OBS identifier for when the modal is not loaded */
        STATS_NOT_LOADED: 'join.popup_not_loaded',

        /** @type {string} - OBS identifier for when the modal is not loaded after the time out */
        STATS_NOT_LOADED_AFTER_TO: 'join.popup_not_loaded_after_timeout',

        /** @type {string} - OBS identifier for when the modal loaded after the time out */
        STATS_LOADED_AFTER_TO: 'join.popup_loaded_after_timeout',
    },

    [SIGN_TYPE.LOGIN]: {
        /** @type {string} - OBS identifier for when the modal is not loaded */
        STATS_NOT_LOADED: 'login.popup_not_loaded',

        /** @type {string} - OBS identifier for when the modal is not loaded after the time out */
        STATS_NOT_LOADED_AFTER_TO: 'login.popup_not_loaded_after_timeout',

        /** @type {string} - OBS identifier for when the modal loaded after the time out */
        STATS_LOADED_AFTER_TO: 'login.popup_loaded_after_timeout',
    },
};
