import React from 'react';
import classnames from 'classnames';
import { Menu } from '@fiverr-private/orca';
import { Domingo } from '@fiverr-private/domingo';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import Link from '../Links/Link';
import { TOP_BAR_SOURCE } from '../../constants';

import styles from './WhyFiverrProMenu.module.scss';
import './WhyFiverrProMenu.styles.scss';

const T_PREFIX = 'header.why_fiverr_pro_menu';

/**
 * @type {(pageSlug: string, query: Record<string, string>) => string}
 */
const getUrl = (pageSlug, query) => {
    const { url } = getContext();
    return Domingo(url).pro().path('content_pages_page', { page_slug: pageSlug }, { query }).url;
};

export const WhyFiverrProMenu = () => {
    const aboutProLink = getUrl('how-it-works', { source: TOP_BAR_SOURCE });
    const whatMakesFreelancerLink = getUrl('about-pro-freelancers', { source: TOP_BAR_SOURCE });
    const projectServiceLink = getUrl('project-partner', { source: 'top_menu' });
    const successStoriesLink = getUrl('success-stories', { source: TOP_BAR_SOURCE });
    const helpCenterLink = 'https://help-pro.fiverr.com/hc/en-us';

    return (
        <Menu className={classnames('header-package business-entry about-menu pro', styles.container)}>
            <div className={styles.section}>
                <div className={styles.sectionTitle}>
                    <I18n k={`${T_PREFIX}.overview`} />
                </div>
                <Menu.Item className={styles.menuItem}>
                    <Link href={aboutProLink}>
                        <div className={styles.title}>
                            <I18n k={`${T_PREFIX}.about_fiverr_pro`} />
                        </div>
                        <div>
                            <I18n k={`${T_PREFIX}.about_fiverr_pro_description`} />
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item className={styles.menuItem}>
                    <Link href={whatMakesFreelancerLink}>
                        <div className={styles.title}>
                            <I18n k={`${T_PREFIX}.what_makes_pro_freelancer`} />
                        </div>
                        <div>
                            <I18n k={`${T_PREFIX}.what_makes_pro_freelancer_description`} />
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item className={styles.menuItem}>
                    <Link href={projectServiceLink}>
                        <div className={styles.title}>
                            <I18n k={`${T_PREFIX}.project_management_service`} />
                        </div>
                        <div>
                            <I18n k={`${T_PREFIX}.project_management_service_description`} />
                        </div>
                    </Link>
                </Menu.Item>
            </div>
            <div className={styles.section}>
                <div className={styles.sectionTitle}>
                    <I18n k={`${T_PREFIX}.resources`} />
                </div>
                <Menu.Item className={styles.menuItem}>
                    <Link href={successStoriesLink}>
                        <div className={styles.title}>
                            <I18n k={`${T_PREFIX}.success_stories`} />
                        </div>
                        <div>
                            <I18n k={`${T_PREFIX}.success_stories_description`} />
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item className={styles.menuItem}>
                    <Link href={helpCenterLink}>
                        <div className={styles.title}>
                            <I18n k={`${T_PREFIX}.help_center`} />
                        </div>
                        <div>
                            <I18n k={`${T_PREFIX}.help_center_description`} />
                        </div>
                    </Link>
                </Menu.Item>
            </div>
        </Menu>
    );
};
