import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { Domingo } from '@fiverr-private/domingo';
import { getContext } from '@fiverr-private/fiverr_context';
import FiverrBusinessProLogo from '@fiverr-private/icons/logos/fiverr_business_pro_logo';
import { grey_1200 } from '@fiverr-private/sass/helpers/index';
import { LOGO_BASE_CLASS, TOP_NAV_SOURCE } from '../../constants';

import './style.scss';

const getUrl = () => `${Domingo(getContext().url).pro().removePath().url}?source=${TOP_NAV_SOURCE}`;

export const FiverrBusinessProLinkLogo = ({ primaryColor = grey_1200, className = '', href = getUrl() }) => (
    <a href={href} className={classNames(LOGO_BASE_CLASS, 'fib-pro-logo', className)} aria-label="Dashboard">
        <FiverrBusinessProLogo primaryColor={primaryColor} />
    </a>
);

FiverrBusinessProLinkLogo.propTypes = {
    className: string,
    primaryColor: string,
    href: string,
};
