import React from 'react';
import { bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { DropdownTriggerButton } from '../DropdownTriggerButton';

export const WhyFiverrProButton = ({ isOpen }) => (
    <DropdownTriggerButton title={<I18n k="header.why_fiverr_pro" />} isOpen={isOpen} />
);

WhyFiverrProButton.propTypes = {
    isOpen: bool,
};
